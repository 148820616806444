import React from "react"
import PropTypes from "prop-types"
import ArrowNegative from "./icons/ArrowNegative"
import { useReverseLanguage } from "./hooks/useReverseLanguage"
import LinkChoice from "./LinkChoice"

const TextLink = ({ text, className, arrowColor, link, goBack }) => {
  return (
    <LinkChoice link={link} to={link.link} style={{ pointerEvents: "all" }}>
      <div className={"flex items-center " + className}>
        <p className="font-bold mb-0.5">{text}</p>
        <ArrowNegative
          className={
            "mt-0.5 " +
            arrowColor +
            (goBack
              ? useReverseLanguage()
                ? " order-last mr-4 transform rotate-180"
                : " order-first mr-4 transform rotate-180"
              : useReverseLanguage()
              ? " order-first ml-4"
              : " order-last ml-4")
          }
        />
      </div>
    </LinkChoice>
  )
}

TextLink.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  arrowColor: PropTypes.string,
  /**
   * If this is a return / go back link, the arrow should be on the left
   */
  goBack: PropTypes.bool,
}

TextLink.defaultProps = {
  arrowColor: "#fff",
  goBack: false,
}

export default TextLink
