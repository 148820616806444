import React, { useEffect, useMemo } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { animated, useSpring } from "react-spring/web"

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]

const ParallaxLayer = styled(animated.div)`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  will-change: transform;
  width: 110%;
  height: 100.5%;
  left: -3%;
`

const ParallaxBackground = ({ bgColor, layers, factor, isVisible, viewed }) => {
  const [spring, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 90, tension: 650, friction: 180 } }))
  const [offsetSpring, setOffset] = useSpring(() => ({ heightOffset: 0, config: { duration: 0 } }))
  // const [offset, setOffset] = useState(0)
  const interpolates = useMemo(() => {
    let trans1 = (x, y) => `translate3d(${x / (factor * 10)}px,${y / (factor + 80)}px,0)`
    let trans2 = (x, y) => `translate3d(${x / (factor * 8)}px,${y / (factor + 80)}px,0)`
    let trans3 = (x, y) => `translate3d(${x / (factor * 6)}px,${y / (factor + 80)}px,0)`
    let trans4 = (x, y) => `translate3d(${x / (factor * 4)}px,${y / (factor + 80)}px,0)`
    let trans5 = (x, y) => `translate3d(${x / (factor * 2)}px,${y / (factor + 80)}px,0)`
    let trans6 = (x, y) => `translate3d(${x / (factor * 8)}px,${y / (factor + 80)}px,0)`
    let trans7 = (x, y) => `translate3d(${x / (factor * 1)}px,${y / (factor + 80)}px,0)`

    let inter = [trans1, trans2, trans3, trans4, trans5, trans6, trans7]

    return inter
  }, [factor])

  const parallaxShift = () => {
    if (isVisible) {
      setOffset({ heightOffset: window.pageYOffset })
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", parallaxShift)

    return () => {
      window.removeEventListener("scroll", parallaxShift)
    }
  })

  console.log("Renders")

  return (
    <div
      className={
        "absolute z-10 left-0 top-0 overflow-hidden w-full h-full hidden lg:flex justify-center items-center transition-opacity delay-75 duration-75 " +
        bgColor +
        " " +
        (viewed ? "opacity-100" : "opacity-0")
      }
      onMouseMove={({ clientX: x, clientY: y }) => {
        if (isVisible) {
          set({ xy: calc(x, y) })
        } else return
      }}
    >
      {layers.map((layer, i) => (
        <ParallaxLayer
          key={"card-" + i}
          className="absolute top-0 left-0 right-0 bottom-0"
          image={layer}
          style={{
            transform: spring.xy.interpolate(interpolates[i]),
            top: offsetSpring.heightOffset.interpolate(heightOffset => `${-30 + heightOffset / 60}%`),
          }}
        />
      ))}
    </div>
  )
}

ParallaxBackground.propTypes = {
  bgColor: PropTypes.string,
  layers: PropTypes.array,
  factor: PropTypes.number,
}

ParallaxBackground.defaultProps = {
  factor: 18,
}

export default ParallaxBackground
