import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Slider from "../../../components/Slider/Slider"

import { track } from "../../TextBold/TextBold.module.css"
import { useReverseLanguage } from "../../../components/hooks/useReverseLanguage"

const Gallery = styled.div`
  min-height: 1030px;
  @media (max-width: 1280px) {
    width: 100%;
    min-height: 464px;
  }
`

const GalleryImage = styled.div`
  height: 387px;
  width: 272px;
  max-width: 100%;
`

const GalleryVariant = ({ media, type }) => {
  const upperColumnStyle =
    "w-1/2 h-full flex flex-col " +
    (type === "normal"
      ? "items-end xl:items-start " + (useReverseLanguage() ? "pl-4" : "pr-4")
      : "items-start " + (useReverseLanguage() ? "pr-4" : "pl-4"))
  const lowerColumnStyle =
    "w-1/2 h-full flex flex-col justify-end " +
    (type === "normal"
      ? " items-start xl:items-end " + (useReverseLanguage() ? "pr-4" : "pl-4")
      : "items-end " + (useReverseLanguage() ? "pl-4" : "pr-4"))

  return (
    <div className="h-auto w-full flex justify-center xl:w-1/2 relative my-12 xl:my-0">
      <Gallery className="w-full hidden sm:flex">
        <div className={type === "normal" ? upperColumnStyle : lowerColumnStyle}>
          <GalleryImage className="relative overflow-hidden rounded-2xl">
            <img
              src={media[0].link}
              alt={media[0].alt}
              className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full"
            />
          </GalleryImage>
          <GalleryImage className="relative overflow-hidden rounded-2xl mt-8">
            <img
              src={media[1].link}
              alt={media[1].alt}
              className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full"
            />
          </GalleryImage>
        </div>
        <div className={type === "normal" ? lowerColumnStyle : upperColumnStyle}>
          <GalleryImage className="relative overflow-hidden rounded-2xl">
            <img
              src={media[2].link}
              alt={media[2].alt}
              className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full"
            />
          </GalleryImage>
          <GalleryImage className="relative overflow-hidden rounded-2xl mt-8">
            <img
              src={media[3].link}
              alt={media[3].alt}
              className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full"
            />
          </GalleryImage>
        </div>
      </Gallery>
      <div className="w-full block sm:hidden">
        <Slider
          trackStyles={track}
          options={{
            perView: 2,
            peak: 0,
            gap: 20,
            breakpoints: {
              550: {
                peek: { before: 0, after: 200 },
                perView: 1,
              },
              414: {
                peek: { before: 0, after: 50 },
                perView: 1,
              },
            },
          }}
        >
          {media.map((image, i) => {
            return (
              <li key={image.text + "-" + i}>
                <GalleryImage className="relative overflow-hidden rounded-2xl">
                  <img
                    src={image.link}
                    alt={image.alt}
                    className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full"
                  />
                </GalleryImage>
              </li>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

GalleryVariant.propTypes = {
  media: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  type: PropTypes.oneOf(["normal", "reversed"]),
}

export default GalleryVariant
