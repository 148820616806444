import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import PalButton from "../../components/buttons/PalButton"
import Container from "../../components/containers/Container"
import { classes } from "../../utils/paloma-helpers"
import { goTo } from "../../utils/helpers"
import GalleryVariant from "./variants/GalleryVariant"
import SingeImageVariant from "./variants/SingleImageVariant"
import bulletListBlue from "../../assets/images/bulletListBlue.svg"
import bulletListOrange from "../../assets/images/bulletListOrange.svg"
import { useReverseLanguage } from "../../components/hooks/useReverseLanguage"

const TextImageWrapper = styled.div`
  max-width: 1160px;
`

const TextList = styled.ul`
  li {
    background-image: ${({ backgroundColor }) =>
      backgroundColor === "bg-bg" || backgroundColor === "bg-white" ? `url(${bulletListBlue})` : `url(${bulletListOrange})`};
    background-repeat: no-repeat;
    background-position-x: ${({ reverseLanguage }) => (reverseLanguage ? "right" : "")};
    background-position-y: 8px;
    padding: ${({ reverseLanguage }) => (reverseLanguage ? "4px 32px 4px 0" : "4px 0 4px 32px")};
  }
`

const TextImage = ({ content }) => {
  const { formatMessage } = useIntl()

  const { headline, description, media, type, buttons, backgroundColor, list } = content
  const gallery = Array.isArray(media)

  let reverseLanguage = useReverseLanguage()

  const wrapperTypeOptions = {
    "lg:flex-row": type === "normal" && !gallery,
    "xl:flex-row": type === "normal" && gallery,
    "xl:flex-row-reverse": type === "reversed" && gallery,
    "lg:flex-row-reverse": type === "reversed" && !gallery,
  }
  const textColumnTypeOptions = {
    "lg:w-1/2 lg:pt-16 lg:items-start": !gallery,
    "lg:pl-24 lg:text-right": type === "normal" && !gallery && reverseLanguage,
    "lg:pr-24 lg:text-left": type === "normal" && !gallery && !reverseLanguage,
    "lg:pr-24 lg:text-right": type === "reversed" && !gallery && reverseLanguage,
    "lg:pl-24 lg:text-left": type === "reversed" && !gallery && !reverseLanguage,

    "xl:w-1/2 xl:pt-16 xl:items-start": gallery,
    "lg:pl-0 xl:text-right": type === "normal" && gallery && reverseLanguage,
    "lg:pr-0 xl:text-left": type === "normal" && gallery && !reverseLanguage,
    "xl:pr-24 xl:text-right": type === "reversed" && gallery && reverseLanguage,
    "xl:pl-24 xl:text-left": type === "reversed" && gallery && !reverseLanguage,
  }

  return (
    <div className={backgroundColor}>
      <Container className="flex justify-center overflow-hidden">
        <TextImageWrapper
          className={classes(wrapperTypeOptions, "relative min-h-screen w-full flex justify-start items-start flex-col py-20 lg:py-40")}
        >
          <div
            className={classes(
              textColumnTypeOptions,
              "relative h-auto lg:h-full w-full flex flex-col items-start sm:items-center text-start sm:text-center space-y-8 pr-0 pt-0"
            )}
          >
            <h1 className="text-h4 md:text-h2 text-blue">{formatMessage({ id: headline })}</h1>
            {list ? (
              <>
                <p className="text-d2 text-blue font-bold">{formatMessage({ id: list.listTitle })}</p>
                <TextList backgroundColor={backgroundColor} reverseLanguage={reverseLanguage}>
                  {list.items.map(item => (
                    <li key={item}>{formatMessage({ id: item })}</li>
                  ))}
                </TextList>
              </>
            ) : (
              <p className="text-gray-500 max-w-max lg:max-w-md">{formatMessage({ id: description })}</p>
            )}
            {buttons && (
              <div className="space-y-4 w-full sm:w-52">
                {buttons.map((button, i) => (
                  <PalButton
                    key={button.text + "-" + i}
                    label={formatMessage({ id: button.text })}
                    color={button.color}
                    variant={button.variant}
                    block={true}
                    action={() => goTo(button.link.link, button.link.internal, button.link.force, button.link.anchor)}
                  />
                ))}
              </div>
            )}
          </div>
          {gallery ? <GalleryVariant media={media} type={type} /> : <SingeImageVariant media={media} />}
        </TextImageWrapper>
      </Container>
    </div>
  )
}

TextImage.propTypes = {
  content: PropTypes.shape({
    media: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    headline: PropTypes.string,
    description: PropTypes.string,
    backgroundColor: PropTypes.string,
    list: PropTypes.object,
    buttons: PropTypes.array,
    type: PropTypes.oneOf(["normal", "reversed"]),
  }),
}

TextImage.defaultProps = {
  type: "normal",
}

export default TextImage
