import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useReverseLanguage } from "../../../components/hooks/useReverseLanguage"

const SingleImage = styled.div`
  min-height: 820px;
  max-width: 580px;

  @media (max-width: 640px) {
    width: 100%;
    min-height: 464px;
  }
`

const SingeImageVariant = ({ media }) => {
  return (
    <div className="h-auto w-full flex justify-center lg:w-1/2 relative my-12 lg:my-0">
      <SingleImage
        className={
          "relative w-full overflow-hidden rounded-2xl " +
          (useReverseLanguage()
            ? "lg:rounded-bl-tier-half lg:rounded-tr-tier-half lg:rounded-tl-none lg:rounded-br-none"
            : "lg:rounded-br-tier-half lg:rounded-tl-tier-half lg:rounded-tr-none lg:rounded-bl-none")
        }
      >
        {media.type && media.type === "image" ? (
          <img
            alt={media.alt}
            src={media.link}
            className={media.fit ? "w-full" : "absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full"}
          />
        ) : (
          <video
            className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full"
            src={media.link}
            playsInline
            muted
            autoPlay
            loop
          />
        )}
      </SingleImage>
    </div>
  )
}

SingeImageVariant.propTypes = {
  media: PropTypes.object,
}

export default SingeImageVariant
