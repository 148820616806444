import React from "react"
import VisibilitySensor from "react-visibility-sensor"

export function withVisibility(WrappedComponent, minTopValue = 300) {
  return class VisibilityComponent extends React.Component {
    state = {
      isVisible: false,
      viewed: false,
    }

    render() {
      return (
        <VisibilitySensor
          partialVisibility
          minTopValue={minTopValue}
          onChange={isVisible => this.setState({ isVisible, viewed: isVisible && !this.state.viewed ? true : this.state.viewed })}
        >
          <WrappedComponent viewed={this.state.viewed} isVisible={this.state.isVisible} {...this.props} />
        </VisibilitySensor>
      )
    }
  }
}
