import Glide from "@glidejs/glide"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import ArrowRight from "../icons/ArrowRight"
import "./Slider.css"

const Slider = ({ element = "glide", saveSlider, options, controls, children, onRunAfter, onSwipeEnd, trackStyles, slideStyles }) => {
  let [slider] = useState(new Glide(`.${element}`, options))

  useEffect(() => {
    slider.mount()
  }, [])

  useEffect(() => {
    slider.mount()
    if (saveSlider) saveSlider(slider)

    // subscribe to an event
    slider.on("run.after", () => {
      if (onRunAfter) onRunAfter()
    })

    slider.on("swipe.end", () => {
      if (onSwipeEnd) onSwipeEnd(slider)
    })

    // cleanup when unmounting component
    return () => {
      slider.destroy()
    }
  }, [slider, saveSlider, onRunAfter, onSwipeEnd])

  return (
    <div className={element}>
      <div className={"glide__track " + (trackStyles || "")} data-glide-el="track">
        <ul className="glide__slides">
          {children.map((slide, index) => {
            return React.cloneElement(slide, {
              key: index,
              className: `glide__slide ` + (slideStyles || ""),
            })
          })}
        </ul>
      </div>

      {controls && (
        <div className="flex justify-between items-center w-full my-10">
          <div
            className="flex items-center justify-center border border-blue rounded-full text-blue cursor-pointer transform rotate-180"
            onClick={() => slider.go("<")}
          >
            <ArrowRight />
          </div>
          <div className="glide__bullets" data-glide-el="controls[nav]">
            {children.map((slide, index) => {
              return <button className="glide__bullet" data-glide-dir={`=${index}`} key={index} />
            })}
          </div>
          <div className="flex items-center justify-center border border-blue rounded-full text-blue cursor-pointer" onClick={() => slider.go(">")}>
            <ArrowRight />
          </div>
        </div>
      )}
    </div>
  )
}

Slider.propTypes = {
  element: PropTypes.string,
  saveSlider: PropTypes.func,
  options: PropTypes.object,
  controls: PropTypes.bool,
  children: PropTypes.node,
  onRunAfter: PropTypes.func,
  onSwipeEnd: PropTypes.func,
}

export default Slider
