import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import { useReverseLanguage } from "../../../components/hooks/useReverseLanguage"

import PalButton from "../../../components/buttons/PalButton"

import scrolldown from "../../../assets/temp/scrolldown.svg"
import { goTo } from "../../../utils/helpers"

const RoundedImageHighlight = styled.div`
  width: 100%;
  height: 464px;

  @media (min-width: 1024px) {
    height: 88vh;
    width: 50vw;
  }
`

const TwoColumn = ({ content }) => {
  const { formatMessage } = useIntl()

  const { headline, subline, media, buttonText, buttonTextTwo, link } = content

  return (
    <>
      <div
        className={
          "relative h-auto lg:h-full w-full flex flex-col justify-center items-center lg:w-1/2 lg:items-start text-center space-y-8 " +
          (useReverseLanguage() ? "lg:text-right pl-0 lg:pl-24" : "lg:text-left pr-0 lg:pr-24")
        }
      >
        <h1 className="text-h4 md:text-h2 text-blue">{formatMessage({ id: headline })}</h1>
        <p className="text-gray-500 max-w-max lg:max-w-md">{formatMessage({ id: subline })}</p>
        <div className="space-y-4 xl:space-y-0 xl:gap-x-4 flex flex-col xl:flex-row">
          <PalButton
            label={formatMessage({ id: buttonText })}
            color="primary"
            action={() => goTo(link.link, link.internal, link.force, link.anchor)}
          />
          {buttonTextTwo && <PalButton label={formatMessage({ id: buttonTextTwo })} color="primary" variant="outline" />}
        </div>
      </div>
      <div className="h-auto lg:h-screen w-full lg:w-1/2 relative my-12 lg:my-0">
        <RoundedImageHighlight
          className={
            "relative lg:absolute h-screen w-full top-0 overflow-hidden rounded-2xl " +
            (useReverseLanguage()
              ? "right-0 lg:right-6 lg:rounded-br-tier lg:rounded-tr-none"
              : "left-0 lg:left-6 lg:rounded-bl-tier lg:rounded-tl-none")
          }
        >
          {media.type && media.type === "image" ? (
            <img
              alt={media.alt}
              src={media.link}
              className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover object-top h-full w-full"
            />
          ) : (
            <video
              className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover object-top h-full w-full"
              src={media.link}
              playsInline
              muted
              autoPlay
              loop
            />
          )}
        </RoundedImageHighlight>
      </div>
      <img
        alt="scroll-down"
        src={scrolldown}
        className="absolute right-0 left-0 m-auto bottom-14 hidden md:block"
        onClick={() => window.scrollTo({ top: window.innerHeight, behavior: "smooth" })}
      />
    </>
  )
}

TwoColumn.propTypes = {
  content: PropTypes.object.isRequired,
}

export default TwoColumn
