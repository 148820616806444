import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import BadgeAppStore from "../../../components/icons/BadgeAppStore"
import BadgeGooglePlay from "../../../components/icons/BadgeGooglePlay"
import PalButton from "../../../components/buttons/PalButton"
import DownloadIcon from "../../../components/icons/DownloadIcon"

// import scrolldown from "../../../assets/temp/scrolldown.svg"
import apple from "../../../assets/temp/apple.svg"
import android from "../../../assets/temp/android.svg"
import { useIsMacOS } from "../../../components/hooks/useIsMacOS"
// import { useReverseLanguage } from "../../../components/hooks/useReverseLanguage"

const Fullscreen = ({ content }) => {
  const { formatMessage, locale } = useIntl()
  let isAppleDevice = useIsMacOS()
  let appUrl = isAppleDevice
    ? "https://apps.apple.com/app/id1436140272?mt=8"
    : "https://play.google.com/store/apps/details?id=com.tier.app&hl=en&gl=US"

  const { headline, subline } = content

  return (
    <>
      <div
        className={
          "relative z-10 w-full space-y-6 sm:space-y-8 " + (locale === "it" || locale === "es" || locale === "fr" ? "max-w-4xl" : "max-w-xl")
        }
      >
        <h1 className="text-h3 md:text-d1 text-white">{formatMessage({ id: headline })}</h1>
        <p className="text-white max-w-md">{formatMessage({ id: subline })}</p>
        <div className={"hidden md:flex gap-x-5 text-white "}>
          <div className="border-2 border-white rounded-md">
            <BadgeGooglePlay />
          </div>
          <div className="border-2 border-white rounded-md">
            <BadgeAppStore />
          </div>
        </div>
        <div className="block md:hidden space-y-4">
          <a href={appUrl}>
            <PalButton iconPrefix={<DownloadIcon />} label={"Get the app"} color="secondary" />
          </a>
          <p className="text-b2 text-white flex items-center">
            Available on:{" "}
            <span className="inline-block ml-2">
              <img alt="apple" src={apple} />
            </span>
            <span className="inline-block ml-2">
              <img alt="android" src={android} />
            </span>
          </p>
        </div>
      </div>
    </>
  )
}

Fullscreen.propTypes = {
  content: PropTypes.object.isRequired,
}

export default Fullscreen
