import React from "react"
import PropTypes from "prop-types"

function ArrowNegative({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M24 12.5c0 6.627-5.373 12-12 12s-12-5.373-12-12S5.373.5 12 .5s12 5.373 12 12zm-7.987-.5H6.78a.5.5 0 000 1h9.233L13.6 15.413a.5.5 0 10.707.707l3.267-3.266a.497.497 0 000-.708.499.499 0 00-.354-.146h-1.207zm1.56.146L14.307 8.88a.5.5 0 10-.707.707L16.013 12"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

ArrowNegative.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ArrowNegative.defaultProps = {
  color: "#FFAE80",
  width: 25,
  height: 24,
}

export default ArrowNegative;
