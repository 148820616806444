import React from "react"
import PropTypes from "prop-types"

function ArrowRight({ width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 34 34"
    >
      <path
        className="stroke-current"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M9.168 17h15.66M19.928 21.9l4.9-4.9-4.9-4.9"
      ></path>
    </svg>
  );
}

ArrowRight.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ArrowRight.defaultProps = {
  width: 34,
  height: 34,
}

export default ArrowRight;
