import React from "react"
import PropTypes from "prop-types"
import { navigate, useIntl } from "gatsby-plugin-intl"

// Components
import TextLink from "../../components/TextLink"
import PalButton from "../../components/buttons/PalButton"
import ParallaxBackground from "../../components/ParallaxBackground"

// Helpers
import { withVisibility } from "../../components/hooks/withVisibility"
import { useReverseLanguage } from "../../components/hooks/useReverseLanguage"

const TextIcon = ({ content, isVisible, viewed }) => {
  const { formatMessage } = useIntl()

  const {
    headline,
    description,
    buttonText,
    buttonLink,
    backgroundImg,
    backgroundColor,
    parallax,
    align,
    textColor,
    cta,
    buttonColor,
    icon,
    iconPosition,
    alt,
  } = content
  const bgColor = backgroundColor ? `bg-${backgroundColor}` : "bg-blue"
  const text = textColor ? `text-${textColor}` : "text-white"

  return (
    <div className={text + " flex justify-center min-h-screen relative px-6 md:px-24 lg:px-32 2xl:px-0 " + (align ? align : "items-center")}>
      {parallax && (
        <ParallaxBackground bgColor={backgroundColor} layers={parallax.layers} factor={parallax.factor} isVisible={isVisible} viewed={viewed} />
      )}
      {backgroundImg && (
        <img
          src={backgroundImg}
          alt="tier-highlight-image"
          className={
            "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full " +
            (parallax ? "object-bottom" : "object-top")
          }
        />
      )}
      {bgColor && <div className={"absolute z-0 h-full w-screen " + bgColor} />}
      <div
        className={"flex lg:flex-row items-center justify-center w-full lg:gap-x-16 " + (iconPosition === "left" ? "flex-col" : "flex-col-reverse")}
        style={{ maxWidth: 1057 }}
      >
        {iconPosition === "left" && (
          <div className={"relative z-20 flex items-center mb-16 justify-center w-full lg:w-2/5 " + (parallax ? "pointer-events-none" : "")}>
            <img className="w-4/6 lg:w-full" alt={alt} src={icon} style={{ maxWidth: 384 }} />
          </div>
        )}
        <div
          className={
            "w-full lg:w-3/5 flex justify-center text-center mb-10 xs:mb-0 " +
            (useReverseLanguage() ? "lg:text-right " : "lg:text-left ") +
            (parallax ? "pointer-events-none" : "")
          }
        >
          <div className="flex flex-col items-center lg:items-start z-10 space-y-6 md:space-y-10 w-full" style={{ maxWidth: 580 }}>
            {headline && <h2 className="text-h5 md:text-h3 xl:text-h2 w-5/6 md:w-full">{formatMessage({ id: headline })}</h2>}
            {description && <p className="text-p2 lg:text-d2">{formatMessage({ id: description })}</p>}
            {buttonText ? (
              cta === "button" ? (
                <PalButton
                  className="md:mt-6 lg:w-52"
                  color={buttonColor}
                  label={formatMessage({ id: buttonText })}
                  action={() => navigate(buttonLink)}
                />
              ) : (
                <TextLink
                  className="mb-10 md:mb-0 md:mt-6"
                  text={formatMessage({ id: buttonText })}
                  link={buttonLink}
                  arrowColor="text-orange-highlight"
                />
              )
            ) : null}
          </div>
        </div>
        {iconPosition === "right" && (
          <div className={"relative z-20 flex items-center mb-16 justify-center w-full lg:w-2/5 " + (parallax ? "pointer-events-none" : "")}>
            <img className="w-4/6 lg:w-full" alt={alt} src={icon} style={{ maxWidth: 384 }} />
          </div>
        )}
      </div>
    </div>
  )
}

TextIcon.propTypes = {
  /**
   * Prop for the background color, passed as a tailwind class
   */
  content: PropTypes.object.isRequired,
}

export default withVisibility(TextIcon)
