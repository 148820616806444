import React, { useRef } from "react"
import PropTypes from "prop-types"

import Container from "../../components/containers/Container"
import Fullscreen from "./variants/Fullscreen"

import scrolldown from "../../assets/temp/scrolldown.svg"
import TwoColumn from "./variants/TwoColumn"
import { useVideo } from "../../components/hooks/useVideo"
import { withVisibility } from "../../components/hooks/withVisibility"
import { useReverseLanguage } from "../../components/hooks/useReverseLanguage"

const HeroMedia = ({ content, isVisible }) => {
  const videoContainer = useRef()
  const { media, type } = content

  useVideo(
    videoContainer,
    media.type === "video" ? media.link : null,
    isVisible,
    "absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover object-top h-full w-full"
  )

  return (
    <div className="relative overflow-hidden">
      {type === "fullscreen" && <div className="absolute left-0 top-0 h-full w-screen bg-gray-500 opacity-20 z-10" />}
      {type === "fullscreen" && (
        <div ref={videoContainer} className="h-full w-screen absolute top-0 left-0 overflow-hidden bg-green">
          {media.type && media.type === "image" && (
            <img
              alt="tier-work"
              src={media.link}
              className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover object-bottom h-full w-full"
            />
          )}
          <img
            alt="scroll-down"
            src={scrolldown}
            className={"absolute bottom-14 hidden md:block cursor-pointer z-20 " + (useReverseLanguage() ? "left-20" : "right-20")}
            onClick={() => window.scrollTo({ top: window.innerHeight, behavior: "smooth" })}
          />
        </div>
      )}
      <Container
        className={
          "relative min-h-initial-height flex justify-start rtl:justify-end rtl:text-right items-end sm:items-center pb-10 sm:pb-0 " +
          (type === "two-column" ? " flex-col lg:flex-row pt-28 md:pt-40 lg:pt-0" : "overflow-hidden")
        }
      >
        {type === "fullscreen" ? <Fullscreen content={content} /> : <TwoColumn content={content} />}
      </Container>
    </div>
  )
}

HeroMedia.propTypes = {
  content: PropTypes.shape({
    media: PropTypes.object,
    headline: PropTypes.string,
    subline: PropTypes.string,
    buttonText: PropTypes.string,
    type: PropTypes.oneOf(["fullscreen", "two-column"]),
  }).isRequired,
  type: PropTypes.oneOf(["fullscreen", "two-column"]),
}

export default withVisibility(HeroMedia)
